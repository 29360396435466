import { bookingsCalendarClick } from '@wix/bi-logger-wixboost-ugc/v2';
import { BookingsQueryParams } from '@wix/bookings-adapter-ooi-wix-sdk';
import { WidgetComponents, WidgetElements } from '../../../../utils/bi/consts';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { navigateToBookingsCalendarPage } from '@wix/bookings-catalog-calendar-viewer-navigation';
import {
  getUrlQueryParamValue,
  BookingsQueryParams as BookingsQueryParamsUtils,
} from '@wix/bookings-catalog-calendar-viewer-utils';

export type OnServiceChange = (serviceId: string, serviceSlug: string) => void;

export const createOnServiceChange =
  ({
    getControllerState,
    context,
  }: ActionFactoryParams<CalendarState, CalendarContext>): OnServiceChange =>
  (serviceId, serviceSlug) => {
    const [state] = getControllerState();
    const { selectedTimezone } = state;
    const {
      wixSdkAdapter,
      biLogger,
      experiments,
      flowAPI: {
        controllerConfig: { wixCodeApi, platformAPIs },
      },
    } = context;

    const referral = experiments.enabled(
      'specs.bookings.isUseUtilsInsteadOfWixSDKEnabled',
    )
      ? getUrlQueryParamValue(wixCodeApi, BookingsQueryParamsUtils.REFERRAL)
      : wixSdkAdapter.getUrlQueryParamValue(BookingsQueryParams.REFERRAL);

    void biLogger.report(
      bookingsCalendarClick({
        component: WidgetComponents.CHANGE_SERVICE,
        element: WidgetElements.MENU_OPTION,
        serviceId,
      }),
    );
    if (experiments.enabled('specs.bookings.navigationFromNewModule')) {
      navigateToBookingsCalendarPage(wixCodeApi, platformAPIs, {
        serviceSlugOrBasket: serviceSlug,
        navigationContext: {
          timezone: selectedTimezone,
          referral,
        },
      });
    } else {
      wixSdkAdapter.navigateToBookingsCalendarPage(serviceSlug, {
        timezone: selectedTimezone,
        referral,
      });
    }
  };
