import { Service, ServiceType } from '@wix/bookings-uou-types';
import { GetActiveFeaturesResponse } from '@wix/ambassador-services-catalog-server/types';
import { isServiceVariantWithStaff } from '../dynamicPricing/dynamicPricing';
import { CalendarState } from '../../components/BookingCalendar/controller';
import { FilterTypes } from '../../components/BookingCalendar/ViewModel/filterViewModel/filterViewModel';

export const isCalendarFlow = (service: Service) =>
  service?.info.type !== ServiceType.COURSE &&
  service?.policy.isBookOnlineAllowed;

export function isBookingsEnabled({
  servicesTypes,
  activeFeatures,
  isTemplateMode,
}: {
  servicesTypes: ServiceType[];
  activeFeatures: GetActiveFeaturesResponse;
  isTemplateMode: boolean;
}): boolean {
  if (isTemplateMode) {
    return true;
  }

  return servicesTypes.every((serviceType) => {
    switch (serviceType) {
      case ServiceType.GROUP:
        return !!activeFeatures.applicableForGroups;
      case ServiceType.INDIVIDUAL:
        return !!activeFeatures.applicableForIndividual;
      default:
        return true;
    }
  });
}

export const isPendingApprovalFlow = (services: Service[]) =>
  services.some((service) => !!service.policy?.isPendingApprovalFlow);

export const isAutoAssignStaffEnabled = (state: CalendarState) => {
  const { servicesV2InView, serviceVariantsMap, filterOptions } = state;

  return (
    !filterOptions[FilterTypes.STAFF_MEMBER].length &&
    !!servicesV2InView.length &&
    servicesV2InView.every(
      (service) =>
        !isServiceVariantWithStaff(serviceVariantsMap[service.id!]) &&
        service.bookingPolicy?.resourcesPolicy?.enabled &&
        service.bookingPolicy?.resourcesPolicy?.autoAssignAllowed,
    )
  );
};
